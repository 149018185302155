<template>
  <div v-if="statusPage.active" class="max-w-7xl mx-auto sm:px-6 py-6 px-8">

    <ul class="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3">
      <li class="col-span-1 lg:col-start-2 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
        <div class="flex-1 flex flex-col p-8">
          <img class="w-32 h-32 flex-shrink-0 shadow-lg border-4 mx-auto rounded-full"
             :src="statusPage.image"
             alt=""
          />
          <h3 class="mt-2 text-gray-900 text-lg font-medium mb-1">{{statusPage.title}}</h3>
          <div v-if="status" class="mt-1">
            <div v-if="status.type === 'track_deep'">
              <span class="px-2 py-1 text-white text-lg font-medium bg-gray-900 rounded-full">Deep Work</span><br />
              <div v-if="status.size">
                <span class="text-xs uppercase mb-0 pb-0">Time remaining</span>
                <h2 class="z-10 text-gray-900 text-3xl font-bold -mt-2" style="font-family: 'Roboto Mono', monospace;">{{workRemaining.value}}</h2>
                <span class="text-xs uppercase mb-0 pb-0">Time elapsed</span>
                <h2 class="z-10 text-gray-900 text-xl font-bold -mt-2 pt-0" style="font-family: 'Roboto Mono', monospace;">{{workTotal}}</h2>
              </div>
              <div v-else>
                <span class="text-xs uppercase mb-0 pb-0">Time elapsed</span>
                <h2 class="z-10 text-gray-900 text-3xl font-bold -mt-2" style="font-family: 'Roboto Mono', monospace;">{{workTotal}}</h2>
              </div>
              <div v-if="statusPage.deepWorkMessage" class="rounded-md bg-yellow-50 p-4 mt-3">
                <div class="flex">
                  <div class="ml-3 flex-1 md:flex md:justify-center">
                    <p class="text-sm text-yellow-700">
                      {{statusPage.deepWorkMessage}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <span class="px-2 py-1 text-yellow-800 text-lg font-medium bg-yellow-100 rounded-full">Busy</span>
            </div>
          </div>
          <div v-else>
            <span class="px-2 py-1 text-gray-800 text-md font-medium bg-gray-100 rounded-full">Unknown</span>
          </div>

        </div>
        <div v-if="statusPage.emergencyMessage">
          <div class="-mt-px flex divide-x divide-gray-200">
            <div class="-ml-px w-0 flex-1 flex">
              <div class="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
                In an emergency: {{statusPage.emergencyMessage}}
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3">
      <a @click="fetchState" class="cursor-pointer col-span-1 lg:col-start-2 text-center mt-4 text-sm text-green-400">Refresh</a>
    </div>
  </div>
  <div v-else class="max-w-7xl mx-auto sm:px-6 py-6 lg:px-8">
    <div v-if="loaded">
      Status page you are looking for is either disabled or not available any more.
    </div>
  </div>
</template>

<script>
  import { useRoute } from 'vue-router';
  import { ref } from 'vue';
  import { animationInterval } from '../utils/animationInterval';

  export default {
    name: "Status",
    setup() {
      const route = useRoute();
      const status = ref(false);
      const statusPage = ref(false);
      const loaded = ref(false);
      const workTotal = ref('00:00:00');
      const workRemaining = ref({overtime: false, value: '00:00:00'});
      const controller = new AbortController();

      return {
        route,
        status,
        statusPage,
        loaded,
        controller,
        workTotal,
        workRemaining
      }
    },
    computed: {
    },
    methods: {
      fetchState() {
        this.loaded = false;
        let userId = this.route.params.id;
        fetch(`/.netlify/functions/status-public/${userId}`, {
          method: 'POST',
        }).then(response => response.json())
        .then(data => {
          if (data.statusPage.active === false) {
            this.loaded = true;
          } else {
            this.status = data.element;
            this.statusPage = data.statusPage;
          }
        })
        .catch(() => {
          this.loaded = true;
          this.status = false;
        });
      },
      calculateTotal() {
        if (this.status && this.status.start) {
          let total = 0;
          const now = new Date().getTime();
          total = now - this.status.start;

          if (total === 0) {
            this.workTotal = '00:00:00';
          } else {
            const date = new Date(null);
            date.setSeconds(total / 1000);
            const utc = date.toUTCString();
            this.workTotal = utc.substr(utc.indexOf(":") - 2, 8);
          }
        }
      },
      remainingTime() {
        if (this.status && this.status.size) {
          let total = 0;
          const now = new Date().getTime();
          const date = new Date(null);
          let overtime = false;
          let fullTime = ((this.status.size * 60) * 60);

          total = now - this.status.start;
          if (total > 0) {
            fullTime = fullTime - (total / 1000);
            // we are out of the time - overtime
            if (fullTime < 0) {
              fullTime = 0; //Math.abs(fullTime);
              overtime = true;
            }
          }
          date.setSeconds(fullTime);
          const utc = date.toUTCString();
          this.workRemaining = {status: overtime, value: utc.substr(utc.indexOf(":") - 2, 8)};
        }
      },
    },
    mounted() {
      /*
      this.status = {
        "id":"6049d39e-961b-42d0-b697-b7c27e026822",
        "type":"track_deep",
        "start":1615745067548,
        "size": 3
      };
      */

      animationInterval(1000, this.controller.signal, () => {
        this.calculateTotal();
        this.remainingTime();
      });

      this.fetchState();
    },
    unmounted() {
      this.controller.abort();
    }
  };
</script>
